<template>
  <div class="text-center text-wrap">
    <h1 class="display-2 text-wrap">
      {{ $t("landing.usecases.title").toUpperCase() }}
    </h1>
    <br />
    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img
          max-height="50"
          max-width="50"
          src="/icons/environment.png"
        ></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.usecases.1") }}</h2>
        <p style="text-align: left">
          {{ $t("landing.usecases.description.1") }}
          <a
            style="text-decoration: none"
            class="so-accent"
            target="_blank"
            href="https://ljusgarda.se"
            >{{ $t("landing.usecases.description.12") }}</a
          >
        </p>
      </v-col>
    </v-row>

    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img
          max-height="50"
          max-width="50"
          src="/icons/electricity.png"
        ></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.usecases.2") }}</h2>
        <p style="text-align: left">
          {{ $t("landing.usecases.description.2") }}
        </p>
      </v-col>
    </v-row>

    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img max-height="50" max-width="50" src="/icons/movement.png"></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.usecases.3") }}</h2>
        <p style="text-align: left">
          {{ $t("landing.usecases.description.3") }}
        </p>
      </v-col>
    </v-row>

    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img
          max-height="50"
          max-width="50"
          src="/icons/temp-sensor.png"
        ></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.usecases.4") }}</h2>
        <p style="text-align: left">
          {{ $t("landing.usecases.description.4") }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "UseCases",
};
</script>

<style scoped>
</style>