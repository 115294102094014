<template>
  <div>
    <div
      class="so-title"
      style="text-align: center; width: auto; max-width: 100%"
    >
      <v-img
        width="500px"
        max-width="100%"
        :src="
          whiteLabel ? 'so-white-label.png' : 'sensor-online-animated-logo.gif'
        "
        max-height="150px"
        style="margin: 0 auto"
      />
    </div>

    <div class="slogan" style="text-align: center">
      <br />
      <span class="font-weight-bold lightgrey">{{ $t("landing.slogan") }}</span>
    </div>

    <v-row align="center" class="mt-12" v-if="loggedIn">
      <v-btn
        type="button"
        color="#7ca6d8"
        large
        router
        to="/dashboard"
        class="login-btn"
        >{{ $t("common.dashboard") }}</v-btn
      >
    </v-row>

    <v-row align="center" class="mt-12" v-if="!loggedIn">
      <v-btn
        type="button"
        color="#7ca6d8"
        large
        router
        to="/login"
        class="login-btn"
        >{{ $t("common.login") }}</v-btn
      >
    </v-row>

    <v-row
      align="center"
      class="mt-6"
      v-if="loggedIn && permitted('Company.Create')"
    >
      <span
        style="
          text-align: center;
          color: grey;
          margin: 0 auto;
          font-size: 1.2rem;
        "
        >{{ $t("landing.or") }}</span
      >
    </v-row>

    <v-row
      align="center"
      class="mt-6"
      v-if="loggedIn && permitted('Company.Create')"
    >
      <v-btn
        type="button"
        color="#7ca6d8"
        large
        router
        to="/register"
        class="login-btn"
        >{{ $t("common.registerCompany") }}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LoginConetent",
  props: {
    loggedIn: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    ...mapState("configuration", ["license"]),
  },
};
</script>

<style scoped>
.so-title {
  font-size: 3rem;
}

.slogan {
  text-align: left;
  width: 100%;
}

.lightgrey {
  color: rgb(139, 139, 139);
}

.login-btn {
  margin-top: 2rem;
  margin: 0 auto;
  width: 300px !important;
  color: white;
  font-size: 1rem;
}

.button-breathing {
  -webkit-animation: colorBreath 5s ease-out infinite normal;
  animation: colorBreath 5s ease-out infinite normal;
}

@media only screen and (max-width: 800px) {
  .so-title {
    font-size: 2rem;
  }
}
</style>